import instance from './apiConfig';

function token() {
    if (localStorage.getItem('token')) {
        let data = localStorage.getItem('token');
        let parseData = JSON.parse(data);

        return parseData.token;
    }
    return null;
}

function headers() {
    return {
        'Content-Type': 'application/json',
        'x-access-token': token()
    };
}


export function register(data) {
    return instance.post('/user/saveRole', data);
}
export function deleteUser(data) {
    return instance.post('/user/deleteUser', data);
}
export function updateuser(data) {
    return instance.post('/user/updateuser', data);
}
export function login(data) {
    return instance.post('/user/adminlogin', data);
}
export function getAllProviders() {
    return instance.get('/user/getAllProviders');
}
export function getAllRolesUser() {
    return instance.get('/user/getAllRolesUser');
}
export function getAllCustomer(data) {
    return instance.post('/user/getAllCustomer', data);
}

export function saveProduct(data) {
    return instance.post('/product/saveProduct', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token()
        }
    });
}
export function getallProducts() {
    return instance.get('/customer/product/getallProducts');
}
export function getProductDetail(data) {
    return instance.post('/admin/product/getProductDetail', data);
}
export function deleteProducts(data) {
    return instance.post('/product/deleteProducts', data);
}
export function getCategory() {
    return instance.get('/admin/setting/getAdminServices');
}

export function getproductallCategories() {
    return instance.get('/admin/setting/getproductallCategories');
}
export function getallPricingPlans(data) {
    return instance.post('/admin/setting/getallPricingPlans', data);
}

export function updateCategory(data) {
    return instance.post('/admin/setting/updateCategory', data);
}
export function createPricingPlans(data) {
    return instance.post('/admin/setting/createPricingPlans', data);
}
export function updatePricingPlans(data) {
    return instance.post('/admin/setting/updatePricingPlans', data);
}
export function updateProductCategory(data) {
    return instance.post('/admin/setting/updateProductCategory', data);
}
export function updateProductSubCategory(data) {
    return instance.post('/admin/setting/updateProductSubCategory', data);
}
export function createPushNotification(data) {
    return instance.post('/admin/setting/createPushNotification', data);
}
export function editPushNotification(data) {
    return instance.post('/admin/setting/editPushNotification', data);
}
export function sendPushNotification(data) {
    return instance.post('/admin/setting/sendPushNotification', data);
}
export function getallNotification() {
    return instance.get('/admin/setting/getallNotification');
}
export function updateProductSubSubCategory(data) {
    return instance.post('/admin/setting/updateProductSubSubCategory', data);
}
export function getproductCategories(data) {
    return instance.post('/admin/setting/getproductCategories', data);
}
export function getproductSubCategories(data) {
    return instance.post('/admin/setting/getproductSubCategories', data);
}
export function getproductSubsubCategories(data) {
    return instance.post('/admin/setting/getproductSubsubCategories', data);
}
export function createproductCategories(data) {
    return instance.post('/admin/setting/createproductCategories', data);
}
export function createproductsubCategories(data) {
    return instance.post('/admin/setting/createproductsubCategories', data);
}
export function createproductsubsubCategories(data) {
    return instance.post('/admin/setting/createproductsubsubCategories', data);
}
export function companyprofile(data) {
    return instance.post('/user/companyprofile', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token()
        }
    });
}
export function addService(data) {
    return instance.post('/user/addService', data);
}
export function getallCompanies(data) {
    return instance.get('/admin/company/getCompanies', data);
}
export function getAdminCompanies(data) {
    return instance.post('/admin/company/getAdminCompanies', data);
}
export function getSubscribedCompanies(data) {
    return instance.post('/admin/company/getSubscribedCompanies', data);
}
export function updateCompanyVAT(data) {
    return instance.post('/admin/company/updateCompanyVAT', data);
}
export function createUnit(data) {
    return instance.post('/admin/setting/createUnit', data);
}
export function getallUnits() {
    return instance.get('/admin/setting/getallUnit');
}
export function updateUnit(data) {
    return instance.post('/admin/setting/updateUnit', data);
}
export function createBanner(data) {
    return instance.post('/admin/setting/createBanner', data);
}
export function getallBanner() {
    return instance.get('/admin/setting/getallBanner');
}
export function updateBanner(data) {
    return instance.post('/admin/setting/updateBanner', data);
}
export function addtofeatureProducts(data) {
    return instance.post('/admin/product/addtofeatureProducts', data);
}
export function addtofeatureCompanies(data) {
    return instance.post('/admin/company/addtofeatureCompanies', data);
}
export function activateCompanies(data) {
    return instance.post('/admin/company/activateCompanies', data);
}
export function getAdminCompanydetail(data) {
    return instance.post('/admin/company/getAdminCompanydetail', data);
}

export function verifyCompanyDocuments(data) {
    return instance.post('/admin/company/verifyCompanyDocuments', data);
}
export function createBusiness(data) {
    return instance.post('/admin/company/createBusiness', data);
}
export function getCompanyProfile(data) {
    return instance.post('/admin/company/getCompanyProfile', data);
}
export function updateCompany(data) {
    return instance.post('/admin/company/updateCompany', data);
}

export function createWalkThrough(data) {
    return instance.post('/admin/setting/createWalkThrough', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token()
        }
    });
}
export function getallWalkThroughs() {
    return instance.get('/admin/setting/getallWalkThroughs');
}
export function updateWalkThrough(data) {
    return instance.post('/admin/setting/updateWalkThrough', data);
}
export function getallAppSetting() {
    return instance.get('/admin/setting/getallAppSetting');
}
export function getalladminAppSetting() {
    return instance.get('/admin/setting/getalladminAppSetting');
}
export function updateAppSetting(data) {
    return instance.post('/admin/setting/updateAppSetting', data);
}
export function importProducts(data) {
    return instance.post('/admin/product/importProducts', data);
}
export function getAllCategoriesListbyService(data) {
    return instance.post('/admin/product/getAllCategoriesListbyService', data);
}

export function productlist(data) {
    return instance.post('/customer/product/productlist', data);
}
export function gethomecategorypage(data) {
    return instance.post('/home/gethomecategorypage', data);
}
export function getAllCompanyServices() {
    return instance.get('/admin/company/getAllCompanyServices');
}
export function verifyCompanies(data) {
    return instance.post('/admin/company/verifyCompanies', data);
}
export function getAllCompanyProjects() {
    return instance.get('/admin/company/getAllCompanyProjects');
}
export function getallAdminProducts(data) {
    return instance.post('/admin/product/getallAdminProducts',data);
}
export function getAllOrder(data) {
    return instance.post('/admin/order/getAllOrders', data);
}
export function getAllInquiry(data) {
    return instance.post('/admin/inquiry/getAllInquiry',data);
}
export function getAllQuotation(data) {
    return instance.post('/admin/quotation/getAllQuotation', data);
}
export function getAllServices() {
    return instance.get('/admin/setting/getAllServices');
}
export function totalcount() {
    return instance.get('/admin/setting/totalcount');
}

export function getReports() {
    return instance.get('/admin/report/getReports');
}

export function getLatestReport(data) {
    return instance.post('/admin/report/getLatestReport', data);
}

export function getPreviousReport(data) {
    return instance.post('/admin/report/getPreviousReport', data);
}

export function deleteReport(data) {
    return instance.post('/admin/report/deleteReport', data);
}

export function getTutorials() {
    return instance.get('/admin/setting/getAdminTutorials');
}

export function addTutorial(data) {
    return instance.post('/admin/setting/addTutorial', data);
}

export function deleteTutorial(data) {
    return instance.post('/admin/setting/deleteTutorial', data);
}

export function updateTutorial(data) {
    return instance.post('/admin/setting/updateTutorial', data);
}

export function generateOtp(data) {
    return instance.post('/admin/company/generateOtp', data);
}

export function validatePaymentOtp(data) {
    return instance.post('/admin/company/validatePaymentOtp', data);
}


export function getAdvertiseRequest(data) {
    return instance.post('/admin/company/getAdvertiseRequest', data);
}

export function freeTrialSubscription(data) {
    return instance.post('/admin/user/freeTrialSubscription', data);
}

export function getCategoryRequests(data) {
    return instance.post('/admin/services/getCategoryRequests', data);
}

export function updateCategoryRequests(data) {
    return instance.post('/admin/services/updateCategoryRequests', data);
}

export function getAllCancelSubscriptionRequests(data) {
    return instance.post('/admin/services/getAllCancelSubscriptionRequests', data);
}

export function updateSubscriptionRequest(data) {
    return instance.post('/admin/services/updateSubscriptionRequest', data);
}
export function getIndividuals() {
    return instance.get('/admin/company/getIndividuals');
}

export function generate2FaQR(data) {
    return instance.post('/admin/user/generate2FaQR', data);
}

export function enable2FaQR(data) {
    return instance.post('/admin/user/enable2FaQR', data);
}

export function verifyToken(data) {
    return instance.post('/admin/user/verifyToken', data);
}

export function verify2FAOTP(data) {
    return instance.post('/admin/user/verify2FAOTP', data);
}

export function createBlogCategory(data) {
    return instance.post('/admin/setting/createBlogCategory', data);
}

export function updateBlogCategory(data) {
    return instance.post('/admin/setting/updateBlogCategory', data);
}

export function getallBlogCategory(data) {
    return instance.post('/admin/setting/getallBlogCategory', data);
}

export function createBlog(data) {
    return instance.post('/admin/blog/createBlog', data);
}

export function updateBlog(data) {
    return instance.post('/admin/blog/updateBlog', data);
}

export function editBlog(data) {
    return instance.post('/admin/blog/editBlog', data);
}

export function getAllBlogs(data) {
    return instance.post('/admin/blog/getAllBlogs', data);
}

export function newsletterSubscribers(data) {
    return instance.post('/admin/user/newsletterSubscribers', data);
}

export function getNotifications(data) {
    return instance.post('/admin/setting/getNotifications', data);
}

export function readNotification(data) {
    return instance.post('/admin/setting/readNotification', data);
}

//edit product

export function deleteVariationProducts(data) {
    return instance.post('/admin/product/deleteVariationProducts', data);
}

export function getEditProduct(data) {
    return instance.post('/admin/product/getEditProduct', data);
}

export function getServiceById(data) {
    return instance.post('/admin/product/getServiceById', data);
}

export function updateProduct(data) {
    return instance.post('/admin/product/updateProduct', data);
}

export function getCompanyCategories(data) {
    return instance.post('/admin/services/getCategories', data);
}

export function getCompanyServiceById(data) {
    return instance.post('/admin/services/getCompanyServiceById', data);
}

export function updateServices(data) {
    return instance.post('/admin/services/updateServices', data);
}

export function getProjectById(data) {
    return instance.post('/admin/projects/getProjectById', data);
}

export function updateProject(data) {
    return instance.post('/admin/projects/updateProject', data);
}

export function getHomeRepairRequestsAll(data) {
    return instance.post('/admin/services/getHomeRepairRequests', data);
}

export function sendProposalRepairRequest(data) {
    return instance.post('/admin/services/sendProposalRepairRequest', data);
}

export function getAllProjects(data) {
    return instance.post('/admin/project/getAllProjects', data);
}

export function getProjectTransactions(data) {
    return instance.post('/admin/project/getProjectTransactions', data);
}

export function getPaymentReceipts(data) {
    return instance.post('/admin/company/getPaymentReceipts', data);
}

export function getPromotions(data) {
    return instance.post('/admin/promotion/getPromotions', data);
}

export function createPromotion(data) {
    return instance.post('/admin/promotion/createPromotion', data);
}

export function updatePromotion(data) {
    return instance.post('/admin/promotion/updatePromotion', data);
}

export function deletePromotion(data) {
    return instance.post('/admin/promotion/deletePromotion', data);
}

export function getWbReviews(data) {
    return instance.post('/admin/review/getWbReviews', data);
}

export function getSupportList(data) {
    return instance.post('/admin/support/getSupportList', data);
}

export function updateTechnicalSupport(data) {
    return instance.post('/admin/support/updateTechnicalSupport', data);
}

export function getQuotationDetail(data) {
    return instance.post('/admin/quotation/getQuotationDetail', data);
}

export function getInquiryDetail(data) {
    return instance.post('/admin/inquiry/getInquiryDetail', data);
}

export function getRequestMessage(data) {
    return instance.post('/admin/chat/getRequestMessage', data);
}

export function getAdminBanks(data) {
    return instance.post('/admin/setting/getAdminBanks', data);
}

export function addAdminBank(data) {
    return instance.post('/admin/setting/addAdminBank', data);
}

export function updateAdminBank(data) {
    return instance.post('/admin/setting/updateAdminBank', data);
}

export function deleteAdminBank(data) {
    return instance.post('/admin/setting/deleteAdminBank', data);
}

export function updateCompanyBank(data) {
    return instance.post('/admin/company/updateCompanyBank', data);
}

export function getCompanyBanks(data) {
    return instance.post('/admin/company/getCompanyBanks', data);
}