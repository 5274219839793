// material-ui
import React, { useState, useEffect } from 'react';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { Typography } from '@mui/material';
import { IconDashboard } from '@tabler/icons';
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArticleIcon from '@mui/icons-material/Article';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import HomeIcon from '@mui/icons-material/Home';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import AddchartIcon from '@mui/icons-material/Addchart';
import BookIcon from '@mui/icons-material/Book';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
const icons = { IconDashboard };
// project imports

// ==============================|| SIDEBAR MENU LIST ||============================== //
const admin = [
    {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title_en: 'Dashboard',
                title_ar: 'لوحة التحكم',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            },
            {
                id: 'Companies',
                title_en: 'Companies',
                title_ar: 'شركات',
                type: 'item',
                url: '/Companies',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'subscription-analytics',
                title_en: 'Subscription Analytics',
                title_ar: 'تحليلات الاشتراك',
                type: 'item',
                url: '/subscription-analytics',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'company-bank',
                title_en: 'Company Banks',
                title_ar: 'بنوك الشركة',
                type: 'item',
                url: '/company-bank',
                icon: AssuredWorkloadIcon,
                breadcrumbs: false
            },
            {
                id: 'individuals',
                title_en: 'Individuals',
                title_ar: 'افراد',
                type: 'item',
                url: '/individuals',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            // {
            //     id: 'Providers',
            //     title_en: 'Providers',
            //     title_ar: 'مقدمي الخدمات',
            //     type: 'item',
            //     url: '/Providers',
            //     icon: RecentActorsIcon,
            //     breadcrumbs: false
            // },
            {
                id: 'Customers',
                title_en: 'Customers',
                title_ar: 'عملاء',
                type: 'item',
                url: '/Customers',
                icon: PersonPinIcon,
                breadcrumbs: false
            },
            {
                id: 'Products',
                title_en: 'Products',
                title_ar: 'منتجات',
                type: 'item',
                url: '/Products',
                icon: EmojiTransportationIcon,
                breadcrumbs: false
            },
            {
                id: 'Projects',
                title_en: 'Projects',
                title_ar: 'مشاريع',
                type: 'item',
                url: '/projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'ProjectTransactions',
                title_en: 'Project Transactions',
                title_ar: 'معاملة المشروع',
                type: 'item',
                url: '/project-transactions',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'company-services',
                title_en: 'Company Services',
                title_ar: 'خدمات الشركة',
                type: 'item',
                url: '/company-services',
                icon: DesignServicesIcon,
                breadcrumbs: false
            },
            {
                id: 'company-projects',
                title_en: 'Company Projects',
                title_ar: 'مشاريع الشركة',
                type: 'item',
                url: '/company-projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'push-notification',
                title_en: 'Push Notification',
                title_ar: 'ارسال اشعارات',
                type: 'item',
                url: '/push-notification',
                icon:  NotificationsIcon,
                breadcrumbs: false
            },
            {
                id: 'Orders',
                title_en: 'Orders',
                title_ar: 'الطلبات',
                type: 'item',
                url: '/Orders',
                icon: AddShoppingCartIcon,
                breadcrumbs: false
            },
            {
                id: 'Inquiries',
                title_en: 'Inquiries',
                title_ar: 'استفسارات',
                type: 'item',
                url: '/Inquiries',
                icon: SummarizeIcon,
                breadcrumbs: false
            },
            {
                id: 'Quotations',
                title_en: 'Quotations',
                title_ar: 'عروض الأسعار',
                type: 'item',
                url: '/Quotations',
                icon: ArticleIcon,
                breadcrumbs: false
            },
            {
                id: 'Blogs',
                title_en: 'Blogs',
                title_ar: 'مدونة',
                type: 'item',
                url: '/blogs',
                icon: BookIcon,
                breadcrumbs: false
            },
            {
                id: 'Whatsapp',
                title_en: 'Whatsapp Business',
                title_ar: 'واتساب للأعمال',
                type: 'collapse',
                icon: WhatsAppIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'promotions',
                        title_en: 'Promotions',
                        title_ar: 'العروض الترويجية',
                        type: 'item',
                        url: '/promotions',
                        breadcrumbs: false
                    },
                    {
                        id: 'offers',
                        title_en: 'Offers',
                        title_ar: 'عروض',
                        type: 'item',
                        url: '/offers',
                        breadcrumbs: false
                    },
                    {
                        id: 'wb-feedback',
                        title_en: 'Feedback',
                        title_ar: 'ملاحظات',
                        type: 'item',
                        url: '/wb-feedback',
                        breadcrumbs: false
                    },
                ]
            },
            {
                id: 'support',
                title_en: 'Support',
                title_ar: 'الدعم',
                type: 'collapse',
                icon: InfoIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'whatsapp-support',
                        title_en: 'Whatsapp Support',
                        title_ar: 'العروض الترويجية',
                        type: 'collapse',
                        breadcrumbs: false,
                        children: [
                            {
                                id: 'technical-support',
                                title_en: 'Technical Support',
                                title_ar: 'الدعم الفني',
                                type: 'item',
                                url: '/whatsapp/support/technical',
                                breadcrumbs: false
                            },
                            {
                                id: 'payment-support',
                                title_en: 'Payment Support',
                                title_ar: 'دعم الدفع',
                                type: 'item',
                                url: '/whatsapp/support/payment',
                                breadcrumbs: false
                            },
                            {
                                id: 'order-support',
                                title_en: 'Order Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/order',
                                breadcrumbs: false
                            },
                            {
                                id: 'general-support',
                                title_en: 'General Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/general',
                                breadcrumbs: false
                            },
                        ]
                    },
                    // {
                    //     id: 'app-support',
                    //     title_en: 'App Support',
                    //     title_ar: 'العروض الترويجية',
                    //     type: 'collapse',
                    //     breadcrumbs: false,
                    //     children: [
                    //         {
                    //             id: 'technical-support1',
                    //             title_en: 'Technical Support',
                    //             title_ar: 'الدعم الفني',
                    //             type: 'item',
                    //             url: '/app/technical-support',
                    //             breadcrumbs: false
                    //         },
                    //     ]
                    // },
                ]
            },
            {
                id: 'newsletter',
                title_en: 'Newsletter Subscribers',
                title_ar: 'المشتركين في النشرة الإخبارية',
                type: 'item',
                url: '/newsletter-subscribers',
                icon: UnsubscribeOutlinedIcon,
                breadcrumbs: false
            },
            {
                id: 'Advertise',
                title_en: 'Advertise with us',
                title_ar: 'أعلن معنا',
                type: 'item',
                url: '/advertise-us',
                icon: FeaturedVideoIcon,
                breadcrumbs: false
            },
            {
                id: 'Reports',
                title_en: 'Report',
                title_ar: 'تقرير',
                type: 'item',
                url: '/reports',
                icon: AssessmentIcon,
                breadcrumbs: false
            },
            {
                id: 'Units',
                title_en: 'Units',
                title_ar: 'الوحدات',
                type: 'item',
                url: '/Units',
                icon: AdUnitsIcon,
                breadcrumbs: false
            },
            {
                id: 'CategoryRequest',
                title_en: 'Category Requests',
                title_ar: 'طلبات الفئة',
                type: 'item',
                url: '/category-request',
                icon: AddchartIcon,
                breadcrumbs: false
            },
            {
                id: 'Customer Home',
                title_en: 'Customer Home',
                title_ar: 'منزل العميل',
                type: 'collapse',
                url: '/CustomerHome',
                icon: HomeIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'Banners',
                        title_en: 'Banners',
                        title_ar: 'لافتات',
                        type: 'item',
                        url: '/Banners',
                        breadcrumbs: false
                    },
                    {
                        id: 'FeatureProduct',
                        title_en: 'Feature Products',
                        title_ar: 'المنتجات المميزة',
                        type: 'item',
                        url: '/FeatureProduct',
                        breadcrumbs: false
                    },
                    {
                        id: 'FeatureCompany',
                        title_en: 'Feature Companies',
                        title_ar: 'شركات مميزة',
                        type: 'item',
                        url: '/FeatureCompanies',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'HomepageVideo',
                    //     title: 'Home Page Video',
                    //     type: 'item',
                    //     url: '/HomePageVideo',
                    //     breadcrumbs: false
                    // }
                ]
            },
            {
                id: 'Settings DropDown',
                title_en: 'Settings',
                title_ar: 'إعدادات',
                type: 'collapse',
                url: '/setting',
                icon: SettingsOutlinedIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'Settings',
                        title_en: 'Settings',
                        title_ar: 'إعدادات',
                        type: 'item',
                        url: '/Setting',
                        breadcrumbs: false
                    },
                    {
                        id: 'Services',
                        title_en: 'Services & Categories',
                        title_ar: "الخدمات والفئات",
                        type: 'item',
                        url: '/Services_Categories',
                        breadcrumbs: false
                    },
                    {
                        id: 'Tutorials',
                        title_en: 'Tutorials',
                        title_ar: "درس تعليمي",
                        type: 'item',
                        url: '/tutorials',
                        breadcrumbs: false
                    },
                    {
                        id: 'blog-categories',
                        title_en: 'Make Blog Categories',
                        title_ar: "فئات المدونة",
                        type: 'item',
                        url: '/blog-categories',
                        breadcrumbs: false
                    },
                ]
            },
        ]
    }
];
const customer = [
    {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title_en: 'Dashboard',
                title_ar: 'لوحة التحكم',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            },
            {
                id: 'Companies',
                title_en: 'Companies',
                title_ar: 'شركات',
                type: 'item',
                url: '/Companies',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'subscription-analytics',
                title_en: 'Subscription Analytics',
                title_ar: 'تحليلات الاشتراك',
                type: 'item',
                url: '/subscription-analytics',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'individuals',
                title_en: 'Individuals',
                title_ar: 'افراد',
                type: 'item',
                url: '/individuals',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            // {
            //     id: 'Providers',
            //     title_en: 'Providers',
            //     title_ar: 'مقدمي الخدمات',
            //     type: 'item',
            //     url: '/Providers',
            //     icon: RecentActorsIcon,
            //     breadcrumbs: false
            // },
            {
                id: 'Customers',
                title_en: 'Customers',
                title_ar: 'عملاء',
                type: 'item',
                url: '/Customers',
                icon: PersonPinIcon,
                breadcrumbs: false
            },
            {
                id: 'Orders',
                title_en: 'Orders',
                title_ar: 'الطلبات',
                type: 'item',
                url: '/Orders',
                icon: AddShoppingCartIcon,
                breadcrumbs: false
            },

            {
                id: 'support',
                title_en: 'Support',
                title_ar: 'الدعم',
                type: 'collapse',
                icon: InfoIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'whatsapp-support',
                        title_en: 'Whatsapp Support',
                        title_ar: 'العروض الترويجية',
                        type: 'collapse',
                        breadcrumbs: false,
                        children: [
                            {
                                id: 'technical-support',
                                title_en: 'Technical Support',
                                title_ar: 'الدعم الفني',
                                type: 'item',
                                url: '/whatsapp/support/technical',
                                breadcrumbs: false
                            },
                            {
                                id: 'payment-support',
                                title_en: 'Payment Support',
                                title_ar: 'دعم الدفع',
                                type: 'item',
                                url: '/whatsapp/support/payment',
                                breadcrumbs: false
                            },
                            {
                                id: 'order-support',
                                title_en: 'Order Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/order',
                                breadcrumbs: false
                            },
                            {
                                id: 'general-support',
                                title_en: 'General Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/general',
                                breadcrumbs: false
                            },
                        ]
                    },
                    // {
                    //     id: 'app-support',
                    //     title_en: 'App Support',
                    //     title_ar: 'العروض الترويجية',
                    //     type: 'collapse',
                    //     breadcrumbs: false,
                    //     children: [
                    //         {
                    //             id: 'technical-support',
                    //             title_en: 'Technical Support',
                    //             title_ar: 'الدعم الفني',
                    //             type: 'item',
                    //             url: '/app/technical-support',
                    //             breadcrumbs: false
                    //         },
                    //     ]
                    // },
                ]
            },
        ]
    }
];

const marketing = [
    {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title_en: 'Dashboard',
                title_ar: 'لوحة التحكم',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            },
            {
                id: 'Companies',
                title_en: 'Companies',
                title_ar: 'شركات',
                type: 'item',
                url: '/Companies',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'subscription-analytics',
                title_en: 'Subscription Analytics',
                title_ar: 'تحليلات الاشتراك',
                type: 'item',
                url: '/subscription-analytics',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'individuals',
                title_en: 'Individuals',
                title_ar: 'افراد',
                type: 'item',
                url: '/individuals',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'Customers',
                title_en: 'Customers',
                title_ar: 'عملاء',
                type: 'item',
                url: '/Customers',
                icon: PersonPinIcon,
                breadcrumbs: false
            },
            {
                id: 'Products',
                title_en: 'Products',
                title_ar: 'منتجات',
                type: 'item',
                url: '/Products',
                icon: EmojiTransportationIcon,
                breadcrumbs: false
            },
            {
                id: 'Projects',
                title_en: 'Projects',
                title_ar: 'مشاريع',
                type: 'item',
                url: '/projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'company-services',
                title_en: 'Company Services',
                title_ar: 'خدمات الشركة',
                type: 'item',
                url: '/company-services',
                icon: DesignServicesIcon,
                breadcrumbs: false
            },
            {
                id: 'company-projects',
                title_en: 'Company Projects',
                title_ar: 'مشاريع الشركة',
                type: 'item',
                url: '/company-projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'push-notification',
                title_en: 'Push Notification',
                title_ar: 'ارسال اشعارات',
                type: 'item',
                url: '/push-notification',
                icon: NotificationsIcon,
                breadcrumbs: false
            },
            {
                id: 'Orders',
                title_en: 'Orders',
                title_ar: 'الطلبات',
                type: 'item',
                url: '/Orders',
                icon: AddShoppingCartIcon,
                breadcrumbs: false
            },
            {
                id: 'Inquiries',
                title_en: 'Inquiries',
                title_ar: 'استفسارات',
                type: 'item',
                url: '/Inquiries',
                icon: SummarizeIcon,
                breadcrumbs: false
            },
            {
                id: 'Quotations',
                title_en: 'Quotations',
                title_ar: 'عروض الأسعار',
                type: 'item',
                url: '/Quotations',
                icon: ArticleIcon,
                breadcrumbs: false
            },
            {
                id: 'Whatsapp',
                title_en: 'Whatsapp Business',
                title_ar: 'واتساب للأعمال',
                type: 'collapse',
                icon: WhatsAppIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'promotions',
                        title_en: 'Promotions',
                        title_ar: 'العروض الترويجية',
                        type: 'item',
                        url: '/promotions',
                        breadcrumbs: false
                    },
                    {
                        id: 'offers',
                        title_en: 'Offers',
                        title_ar: 'عروض',
                        type: 'item',
                        url: '/offers',
                        breadcrumbs: false
                    },
                    {
                        id: 'wb-feedback',
                        title_en: 'Feedback',
                        title_ar: 'ملاحظات',
                        type: 'item',
                        url: '/wb-feedback',
                        breadcrumbs: false
                    },
                ]
            },
            {
                id: 'Blogs',
                title_en: 'Blogs',
                title_ar: 'مدونة',
                type: 'item',
                url: '/blogs',
                icon: BookIcon,
                breadcrumbs: false
            },
            {
                id: 'Advertise',
                title_en: 'Advertise with us',
                title_ar: 'أعلن معنا',
                type: 'item',
                url: '/advertise-us',
                icon: FeaturedVideoIcon,
                breadcrumbs: false
            },
            {
                id: 'Reports',
                title_en: 'Report',
                title_ar: 'تقرير',
                type: 'item',
                url: '/reports',
                icon: AssessmentIcon,
                breadcrumbs: false
            },
            {
                id: 'Customer Home',
                title_en: 'Customer Home',
                title_ar: 'منزل العميل',
                type: 'collapse',
                url: '/CustomerHome',
                icon: HomeIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'Banners',
                        title_en: 'Banners',
                        title_ar: 'لافتات',
                        type: 'item',
                        url: '/Banners',
                        breadcrumbs: false
                    },
                    {
                        id: 'FeatureProduct',
                        title_en: 'Feature Products',
                        title_ar: 'المنتجات المميزة',
                        type: 'item',
                        url: '/FeatureProduct',
                        breadcrumbs: false
                    },
                    {
                        id: 'FeatureCompany',
                        title_en: 'Feature Companies',
                        title_ar: 'شركات مميزة',
                        type: 'item',
                        url: '/FeatureCompanies',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'Settings',
                title_en: 'Settings',
                title_ar: 'إعدادات',
                type: 'collapse',
                url: '/Setting',
                icon: SettingsOutlinedIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'blog-categories',
                        title_en: 'Make Blog Categories',
                        title_ar: "فئات المدونة",
                        type: 'item',
                        url: '/blog-categories',
                        breadcrumbs: false
                    },
                ]
            }

        ]
    }
];
const sale = [
    {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title_en: 'Dashboard',
                title_ar: 'لوحة التحكم',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            },
            {
                id: 'Companies',
                title_en: 'Companies',
                title_ar: 'شركات',
                type: 'item',
                url: '/Companies',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'subscription-analytics',
                title_en: 'Subscription Analytics',
                title_ar: 'تحليلات الاشتراك',
                type: 'item',
                url: '/subscription-analytics',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'individuals',
                title_en: 'Individuals',
                title_ar: 'افراد',
                type: 'item',
                url: '/individuals',
                icon: ApartmentIcon,
                breadcrumbs: false
            },
            {
                id: 'Customers',
                title_en: 'Customers',
                title_ar: 'عملاء',
                type: 'item',
                url: '/Customers',
                icon: PersonPinIcon,
                breadcrumbs: false
            },
            {
                id: 'Products',
                title_en: 'Products',
                title_ar: 'منتجات',
                type: 'item',
                url: '/Products',
                icon: EmojiTransportationIcon,
                breadcrumbs: false
            },
            {
                id: 'Projects',
                title_en: 'Projects',
                title_ar: 'مشاريع',
                type: 'item',
                url: '/projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'ProjectTransactions',
                title_en: 'Project Transactions',
                title_ar: 'معاملة المشروع',
                type: 'item',
                url: '/project-transactions',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'company-services',
                title_en: 'Company Services',
                title_ar: 'خدمات الشركة',
                type: 'item',
                url: '/company-services',
                icon: DesignServicesIcon,
                breadcrumbs: false
            },
            {
                id: 'company-projects',
                title_en: 'Company Projects',
                title_ar: 'مشاريع الشركة',
                type: 'item',
                url: '/company-projects',
                icon: AccountTreeIcon,
                breadcrumbs: false
            },
            {
                id: 'Orders',
                title_en: 'Orders',
                title_ar: 'الطلبات',
                type: 'item',
                url: '/Orders',
                icon: AddShoppingCartIcon,
                breadcrumbs: false
            },
            {
                id: 'Inquiries',
                title_en: 'Inquiries',
                title_ar: 'استفسارات',
                type: 'item',
                url: '/Inquiries',
                icon: SummarizeIcon,
                breadcrumbs: false
            },
            {
                id: 'Quotations',
                title_en: 'Quotations',
                title_ar: 'عروض الأسعار',
                type: 'item',
                url: '/Quotations',
                icon: ArticleIcon,
                breadcrumbs: false
            },
            {
                id: 'Advertise',
                title_en: 'Advertise with us',
                title_ar: 'أعلن معنا',
                type: 'item',
                url: '/advertise-us',
                icon: FeaturedVideoIcon,
                breadcrumbs: false
            },
            {
                id: 'Reports',
                title_en: 'Report',
                title_ar: 'تقرير',
                type: 'item',
                url: '/reports',
                icon: AssessmentIcon,
                breadcrumbs: false
            },

            {
                id: 'support',
                title_en: 'Support',
                title_ar: 'الدعم',
                type: 'collapse',
                icon: InfoIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'whatsapp-support',
                        title_en: 'Whatsapp Support',
                        title_ar: 'العروض الترويجية',
                        type: 'collapse',
                        breadcrumbs: false,
                        children: [
                            {
                                id: 'technical-support',
                                title_en: 'Technical Support',
                                title_ar: 'الدعم الفني',
                                type: 'item',
                                url: '/whatsapp/support/technical',
                                breadcrumbs: false
                            },
                            {
                                id: 'payment-support',
                                title_en: 'Payment Support',
                                title_ar: 'دعم الدفع',
                                type: 'item',
                                url: '/whatsapp/support/payment',
                                breadcrumbs: false
                            },
                            {
                                id: 'order-support',
                                title_en: 'Order Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/order',
                                breadcrumbs: false
                            },
                            {
                                id: 'general-support',
                                title_en: 'General Support',
                                title_ar: 'دعم الطلب',
                                type: 'item',
                                url: '/whatsapp/support/general',
                                breadcrumbs: false
                            },
                        ]
                    },
                    // {
                    //     id: 'app-support',
                    //     title_en: 'App Support',
                    //     title_ar: 'العروض الترويجية',
                    //     type: 'collapse',
                    //     breadcrumbs: false,
                    //     children: [
                    //         {
                    //             id: 'technical-support',
                    //             title_en: 'Technical Support',
                    //             title_ar: 'الدعم الفني',
                    //             type: 'item',
                    //             url: '/app/technical-support',
                    //             breadcrumbs: false
                    //         },
                    //     ]
                    // },
                ]
            },
        ]
    }
];
const defaultRoute = [
    {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title_en: 'Dashboard',
                title_ar: 'لوحة التحكم',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            }
        ]
    }
];

const MenuList = () => {
    const parse = JSON.parse(localStorage.getItem('auth_user_type'));
    const current_role = parse?.type;
    const navItems = (
        current_role === 'ADMIN' || current_role === 'DEVELOPER' || current_role === 'SUB-ADMIN'
            ? admin
            : current_role === 'CUSTOMER-SUPPORT'
            ? customer
            : current_role === 'MARKETING'
            ? marketing
            : current_role === 'SALE'
            ? sale
            : defaultRoute
    )?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
