import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Link
} from '@mui/material';
import AWS from 'aws-sdk';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {
    updateuser,
    getAdminBanks,
    addAdminBank,
    deleteAdminBank,
    updateAdminBank,
    getCompanyBanks,
    updateCompanyBank, readNotification
} from 'api/api';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from "@mui/lab/LoadingButton";

const CompanyBank = () => {
    const current = JSON.parse(localStorage.getItem('auth_user'));
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [bankOpen, setBankOpen] = useState(false);
    const [bankData, setBankData] = useState();
    const [bankInfoOpen, setBankInfoOpen] = useState();
    const { t, i18n } = useTranslation();

    const handleBankInfoClose = () => {
        setBankInfoOpen(false);
        setBankData();
    };

    const handleBankClose = () => {
        setBankOpen(false);
        setBankData();
    };

    useEffect(() => {
        getCompanyBanks({user_id: current?.user?._id}).then((res) => {
            const response = res.data;
            setIsActive(false);
            if(response.code === 1){
                setrows(res.data.data);
                setIsActive(false);
            }
            else{
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        }).catch((err) => {
            setIsActive(false);
            if (err?.response?.status === 401) {
                toast.error(err.response?.data?.error, toastOptions);
            }
        });

        readNotification({user_id: current?.user?._id, type: 'BANK'});
    }, []);

    const columns = useMemo(() => [
        {
            field: 'bank_name',
            headerName: t('Bank Name'),
            flex: 1,

            renderCell: ({ row }) => {
                return (row.bank_name);
            }
        },
        { field: 'acc_holder_name', headerName: t('Account Holder Name'), flex: 1,
            renderCell: ({row}) => {
                return (row.acc_holder_name);
            },
        },
        { field: 'iban', headerName: t('IBAN'), flex: 1,
            renderCell: ({row}) => {
                return (row.iban);
            },
        },
        // { field: 'account_number', headerName: t('Account Number'), flex: 1,
        //     renderCell: ({row}) => {
        //         return (row.account_number);
        //     },
        // },
        // { field: 'attachment', headerName: t('Attachment'), flex: 1,
        //     renderCell: ({row}) => {
        //         return (
        //             row.attachment?<Button component={Link} target="_blank" href={row.attachment} variant="contained" color="secondary" size="small">
        //                 {t('View')}
        //             </Button>:('N/A')
        //         );
        //     },
        // },
        { field: 'company', headerName: t('Company'), flex: 1,
            renderCell: ({row}) => {
                return (row.company?.name);
            },
        },
        // { field: 'service', headerName: t('Service'), flex: 1,
        //     renderCell: ({row}) => {
        //         return (i18n.language==='ar'?row.service?.title_ar:row.service?.title_en);
        //     },
        // },
        { field: 'status', headerName: t('Status'), flex: 1,
            renderCell: ({row}) => {
                return (
                    row.is_verified?
                <Chip label={t('Verified')} color="success" />:<Chip label={t('Not Verified')} color="error" />
                );
            },
        },
        { field: 'action', headerName: t('Action'), flex: 1,

            renderCell: ({row}) => {
                return (
                    <React.Fragment>
                        <Button variant="contained" color="secondary" size="small" onClick={() => {
                            setBankOpen(true);
                            setBankData(row);
                        }}>{t('Update')}</Button>
                        <Button variant="contained" sx={{mx: 1}} color="secondary" size="small" onClick={() => {
                            setBankInfoOpen(true);
                            setBankData(row);
                        }}>{t('View Info')}</Button>
                    </React.Fragment>
                )
            }
        },
    ]);

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={11} md={11} sm={11} xs={11}>
                                        <Typography variant="h3">{t('Company Bank Accounts')}</Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {' '}
                                        <DataGrid
                                            getRowHeight={() => 'auto'}
                                            getEstimatedRowHeight={() => 200}
                                            rows={rows}
                                            getRowId={(row) => row._id}
                                            disableRowSelectionOnClick
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            sx={{
                                                border: 0,
                                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                                    outline: 'none !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {
                bankData && (
                    <React.Fragment>
                        <Dialog open={bankOpen} onClose={handleBankClose} fullWidth maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Update Bank')}
                            </DialogTitle>
                            <Formik
                                initialValues={{
                                    user_id: current?.user?._id,
                                    bank_id: bankData?._id,
                                    is_verified: bankData?.is_verified,
                                }}
                                validationSchema={Yup.object().shape({
                                    is_verified: Yup.boolean().required(t('Status is required')),
                                })}
                                onSubmit={async (values) => {
                                    setButtonLoading(true);
                                    updateCompanyBank(values)
                                        .then((res) => {
                                            const response = res.data;
                                            setButtonLoading(false);
                                            if(response.code===1){
                                                toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                                setrows(res.data.data);
                                                handleBankClose();
                                            }
                                            else{
                                                toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                            }

                                        })
                                        .catch((err) => {
                                            setButtonLoading(false);
                                            if (err?.response?.status === 401) {
                                                toast.error(err.response?.data?.error, toastOptions);
                                            }
                                        });

                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <DialogContent>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid item lg={12} md={12} xs={12}>
                                                    <Typography sx={{ mb: 1 }}>{t('Status')}</Typography>
                                                    <Select
                                                        fullWidth
                                                        name="is_verified"
                                                        value={values.is_verified}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}>
                                                        <MenuItem key={1} value={true}>{t('Verify')}</MenuItem>
                                                        <MenuItem key={2} value={false}>{t('Un Verify')}</MenuItem>
                                                    </Select>
                                                    {touched.is_verified && errors.is_verified && (
                                                        <FormHelperText error id="standard-weight-helper-text-is_verified-login">
                                                            {errors.is_verified}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleBankClose} variant="outlined" color="error">
                                                {t('Cancel')}
                                            </Button>
                                            <LoadingButton type="submit" color="secondary" variant="contained" loading={buttonLoading}
                                                           loadingPosition="center">
                                                {t('Update')}
                                            </LoadingButton>
                                        </DialogActions>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        <Dialog open={bankInfoOpen} onClose={handleBankInfoClose} fullWidth maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Bank Info')}
                            </DialogTitle>
                            <DialogContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Bank Name')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.bank_name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Account Holder Name')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.account_number}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Account Number')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.acc_holder_name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('IBAN')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.iban}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{my:1}}>
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Attachment')}</Typography>
                                            <Button href={bankData?.attachment} target="_blank" size="small" variant="contained" color="secondary" component={Link}>
                                                {t('View')}
                                            </Button>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Service')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{i18n.language==='ar'?bankData?.service?.title_ar:bankData?.service?.title_en}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Company')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.company?.name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Phone')}</Typography>
                                            {
                                                bankData?.company?.phone ? (
                                                    <Link href={`tel:${bankData?.company?.phone}`} sx={{ mb: 1 }}>{
                                                        bankData?.company?.phone[0] === '5'?('0'+bankData?.company?.phone):bankData?.company?.phone
                                                    }</Link>
                                                ):(
                                                    'N/A'
                                                )
                                            }
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Email')}</Typography>
                                            {
                                                bankData?.company?.email ? (
                                                    <Link href={`mailto:${bankData?.company?.email}`} sx={{ mb: 1 }}>{bankData?.company?.email}</Link>
                                                ):(
                                                    'N/A'
                                                )
                                            }
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{my:1}}>
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Status')}</Typography>
                                            {
                                                bankData?.is_verified ? <Chip label={t('Verified')} color="success" />:<Chip label={t('Not Verified')} color="error" />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleBankInfoClose} variant="outlined" color="error">
                                    {t('Close')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )
            }
        </>
    );
};

export default CompanyBank;
