import { Grid } from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Badge from '@mui/material/Badge';
import file_thumb from 'assets/images/file-thumb.png'
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

const DropZone = ({ onChange, limit, display, isFile }) => {
    if(isFile){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls']
            }
        });

        const files = acceptedFiles.map((file) => (
            <Grid item lg={6}>
                {
                    isFile?(
                        <img src={file_thumb} alt="" width={150} />
                    ):(
                        <img src={URL.createObjectURL(file)} alt="" width={150} />
                    )
                }
            </Grid>
        ));

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>

                    <Grid container>{files.length > 0 ? files : <img src={display} alt="" sx={{maxWidth: '100%'}} width={250} />}</Grid>
                </Container>
            </div>
        );
    }
    else if(isFile === false){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'image/*': ['.jpeg', '.png']
            }
        });

        const files = acceptedFiles.map((file) => (
            <Grid item lg={6} key={6}>
                {
                    isFile?(
                        <img key={7} src={file_thumb} alt="" width={150} />
                    ):(
                        <img key={8} src={URL.createObjectURL(file)} alt="" width={150} />
                    )
                }
            </Grid>
        ));

        return (
            <div key={5} className="container">
                <Container key={1} {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input key={2} {...getInputProps()} />
                    <p key={3}>Drag 'n' drop some files here, or click to select files</p>

                    <Grid key={4} container>{files.length > 0 ? files : <img src={display} alt="" sx={{maxWidth: '100%'}} width={100} />}</Grid>
                </Container>
            </div>
        );
    }
    else{
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
        });

        const files = acceptedFiles.map((file) => (
            <Grid item lg={6}>
                {
                    isFile?(
                        <img src={file_thumb} alt="" width={150} />
                    ):(
                        <img src={URL.createObjectURL(file)} alt="" width={150} />
                    )
                }
            </Grid>
        ));

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>

                    <Grid container>{files.length > 0 ? files : <img src={display} alt="" style={{maxWidth: '100%'}} width={250} />}</Grid>
                </Container>
            </div>
        );
    }
};

export default DropZone;
