import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import chatReducer from "./reducers/chatReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    chat: chatReducer,
    customization: customizationReducer
});

export default reducer;
