import { Route, Routes, Navigate } from 'react-router-dom';

// routes
import { StaffProtectedRoute, ClientProtectedRoute, AdminProtectedRoute } from './ProtectedRoutes';
import { lazy } from 'react';
// project imports
import Loadable from 'ui-component/Loadable';
import Main from 'layout/MainLayout';
import Advertise from "../views/Advertise";
import Google2FA from "../views/Authentication/Goole2FA";
import OTP from "../views/Authentication/OTP";
import Blog from "../views/Blog";
import AddBlog from "../views/Blog/AddBlog";
import EditBlog from "../views/Blog/EditBlog";
import BlogCategory from "../views/BlogCategory";
import NewsletterSubscribers from "../views/NewsletterSubscribers";
import PushNotification from "../views/PushNotification";
import EditProduct from "../views/Products/EditProduct";
import EditService from "../views/CompanyServies/EditService";
import EditProject from "../views/CompanyProject/EditProject";
import ProjectTransactionList from "../views/CustomerProject/ProjectTransactionList";
import CompanyBank from "../views/CompanyBank";

const Support = Loadable(lazy(() => import('views/Support')));
const WBFeedback = Loadable(lazy(() => import('views/WBusiness/WBFeedback')));
const Promotions = Loadable(lazy(() => import('views/WBusiness/Promotions')));
const Offers = Loadable(lazy(() => import('views/WBusiness/Offers')));
const Login = Loadable(lazy(() => import('views/Authentication/authentication3/Login3')));
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Services_Categories = Loadable(lazy(() => import('views/Services_Categories')));
const Categories = Loadable(lazy(() => import('views/Services_Categories/Categories')));
const SubCategory = Loadable(lazy(() => import('views/Services_Categories/SubCategory')));
const SubSubCategories = Loadable(lazy(() => import('views/Services_Categories/SubSubCategories')));
const Plan = Loadable(lazy(() => import('views/Services_Categories/Plan')));
const Companies = Loadable(lazy(() => import('views/Companies')));
const CompaniesNew = Loadable(lazy(() => import('views/Companies/indexNew')));
const Individuals = Loadable(lazy(() => import('views/Individuals')));
const CompanyDetails = Loadable(lazy(() => import('views/Companies/Detail')));
const SubscribedCompanies = Loadable(lazy(() => import('views/Companies/SubscribedCompanies')));
const CompanyProject = Loadable(lazy(() => import('views/CompanyProject')));
const CompanyServies = Loadable(lazy(() => import('views/CompanyServies')));
const Banners = Loadable(lazy(() => import('views/CustomerHome/Banners')));
const FeatureCompanies = Loadable(lazy(() => import('views/CustomerHome/FeatureCompanies')));
const FeatureProduct = Loadable(lazy(() => import('views/CustomerHome/FeatureProduct')));
const HomePageVideo = Loadable(lazy(() => import('views/CustomerHome/HomePageVideo')));
const Customers = Loadable(lazy(() => import('views/Customers')));
const Inquiries = Loadable(lazy(() => import('views/Inquiries')));
const InquiryDetail = Loadable(lazy(() => import('views/Inquiries/detail')));
const Orders = Loadable(lazy(() => import('views/Orders')));
const Products = Loadable(lazy(() => import('views/Products')));
const Providers = Loadable(lazy(() => import('views/Providers')));
const Quotations = Loadable(lazy(() => import('views/Quotations')));
const QuotationDetail = Loadable(lazy(() => import('views/Quotations/detail')));
const Setting = Loadable(lazy(() => import('views/Setting')));
const Units = Loadable(lazy(() => import('views/Units')));
const Report = Loadable(lazy(() => import('views/Report')));
const Tutorial = Loadable(lazy(() => import('views/Setting/Tutorial')));
const CategoryRequest = Loadable(lazy(() => import('views/CategoryRequest')));
const CustomerProject = Loadable(lazy(() => import('views/CustomerProject')));
const SubsTransactionList = Loadable(lazy(() => import('views/Companies/SubsTransactionList')));
// ==============================|| ROUTING RENDER ||==============================//

const Logout = () => {
    // localStorage.setItem(
    //     'auth_user',
    //     JSON.stringify({
    //         user: ''
    //     })
    // );
    // localStorage.setItem(
    //     'token',
    //     JSON.stringify({
    //         token: ''
    //     })
    // );
    // localStorage.setItem(
    //     'auth_user_type',
    //     JSON.stringify({
    //         type: ''
    //     })
    // );
    let i18nextLng = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng', i18nextLng);
    return <Navigate to="/login" />;
};

export default function ThemeRoutes() {
    return (
        <Routes>
            <Route exact path="/login" element={<Login />} />

            <Route exact path="/logout" element={<Logout />} />

            <Route exact path="/google2f" element={<Google2FA />} />
            <Route exact path="/otp" element={<OTP />} />

            <Route path="/" element={<ClientProtectedRoute />}>
                <Route exact path="/" element={<Main />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/Services_Categories" element={<Services_Categories />} />
                    <Route path="/Categories" element={<Categories />} />
                    <Route path="/SubCategory" element={<SubCategory />} />
                    <Route path="/SubSubCategories" element={<SubSubCategories />} />
                    <Route path="/push-notification" element={<PushNotification />} />
                    <Route path="/Quotations" element={<Quotations />} />
                    <Route path="/company-bank" element={<CompanyBank />} />
                    <Route path="/quotation-detail/:request_id" element={<QuotationDetail />} />
                    <Route path="/Providers" element={<Providers />} />
                    <Route path="/Products" element={<Products />} />
                    <Route path="/edit-product/:name" element={<EditProduct />} />
                    <Route path="/Orders" element={<Orders />} />
                    <Route path="/Inquiries" element={<Inquiries />} />
                    <Route path="/inquiry-detail/:request_id" element={<InquiryDetail />} />
                    <Route path="/reports" element={<Report />} />
                    <Route path="/Customers" element={<Customers />} />
                    <Route path="/Banners" element={<Banners />} />
                    <Route path="/company-services" element={<CompanyServies />} />
                    <Route path="/edit-Service/:id" element={<EditService />} />
                    <Route path="/company-projects" element={<CompanyProject />} />
                    <Route path="/edit-project/:id" element={<EditProject />} />
                    <Route path="/Companies" element={<Companies />} />
                    {/*<Route path="/Companies" element={<CompaniesNew />} />*/}
                    <Route path="/subscription-analytics" element={<SubscribedCompanies />} />
                    <Route path="/individuals" element={<Individuals />} />
                    <Route path="/Plan" element={<Plan />} />
                    <Route path="/Setting" element={<Setting />} />
                    <Route path="/Units" element={<Units />} />
                    <Route path="/FeatureCompanies" element={<FeatureCompanies />} />
                    <Route path="/FeatureProduct" element={<FeatureProduct />} />
                    <Route path="/HomePageVideo" element={<HomePageVideo />} />
                    <Route path="/CompanyDetails" element={<CompanyDetails />} />
                    <Route path="/tutorials" element={<Tutorial />} />
                    <Route path="/advertise-us" element={<Advertise />} />
                    <Route path="/category-request" element={<CategoryRequest />} />
                    <Route path="/blog-categories" element={<BlogCategory />} />
                    <Route path="/blogs" element={<Blog />} />
                    <Route path="/blogs/add" element={<AddBlog />} />
                    <Route path="/blogs/edit/:name" element={<EditBlog />} />
                    <Route path="/newsletter-subscribers" element={<NewsletterSubscribers />} />
                    <Route path="/projects" element={<CustomerProject />} />
                    <Route path="/subscription-transactions" element={<SubsTransactionList />} />
                    <Route path="/project-transactions" element={<ProjectTransactionList />} />
                    <Route path="/promotions" element={<Promotions />} />
                    <Route path="/wb-feedback" element={<WBFeedback />} />
                    <Route path="/offers" element={<Offers />} />
                    <Route path="/whatsapp/support/:type" element={<Support />} />
                </Route>
            </Route>

            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
}
