import {applyMiddleware, combineReducers, createStore,} from 'redux';
// import reducer from './reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default to localStorage for web
import SecureLS from 'secure-ls';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from "redux-thunk";
import reducer from "./reducer";
const ls = new SecureLS({ isCompression: false });

// ==============================|| REDUX - MAIN STORE ||============================== //


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user', 'city', 'cart_count'], // add the reducers you want to persist
};

const initialState = {
    notification: {
        loading: false,
        data: null
    },
};


const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
